import {Injectable} from '@angular/core';

@Injectable()
export class SoundService {

  constructor() {}

  ding() {
    const snd = new Audio('assets/sounds/ding.wav'); // buffers automatically when created
    snd.play();
  }
}
