const href = window.location.href;
let url;
if (href.indexOf('idcheckportal-yol-t01') !== -1) {
  url = 'https://idcheckportal-yol-t01.sunrise.ch/rest/service';
} else if (href.indexOf('idcheckportal-yol-t02') !== -1) {
  url = 'https://idcheckportal-yol-t02.sunrise.ch/rest/service';
} else if (href.indexOf('idcheckportal-yol-t03') !== -1) {
  url = 'https://idcheckportal-yol-t03.sunrise.ch/rest/service';
} else if (href.indexOf('localhost') !== -1) {
  url = 'https://idcheckportal-yol-t01.sunrise.ch/rest/service';
} else if (href.indexOf('yallo-local') !== -1) {
  url = 'https://idcheckportal-yol-t01.sunrise.ch/rest/service';
} else {
  url = 'https://idcheckportal-yol.swi.srse.net/rest/service';
}

export class Constants {
  public static API_ENDPOINT = url;
}
