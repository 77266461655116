import { BootstrapDate } from './_models/bootstrap-date';
import { Injectable } from '@angular/core';
import { Order } from './_models/order';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor() { }

  getRandomString(length): String {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0987654321'.split('');
    let output = '';
    for (let i = 0; i < length; i++) {
      output += chars['' + this.getRandomInt(0, chars.length - 1)];
    }
    return output;
  }

  getRandomInt(min, max): Number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  toServerJSON = function (): String {
    return this.toJSON().split('.')[0] + 'Z';
  };

  // dateToBootstrap(date: string): BootstrapDate {
  //   return {
  //     year: +date.split('-')[0],
  //     month: +date.split('-')[1],
  //     day: +date.split('-')[2].split('T')[0]
  //   };
  // }

  // BootstrapToDate(date: { year: number, month: number, day: number } | string): String {
  //   if (typeof date === 'string') { // sometimes we get a string, thank you ngBootstrap.
  //     return date;
  //   }
  //   const d = new Date(date.year, date.month - 1, date.day + 1);
  //   return d.toJSON().split('.')[0] + 'Z'; // "2018-07-19T22:00:00Z"
  // }

  serverDateToString(date: string): string {
    return ''
      + date.substring(8, 10)
      + '/'
      + date.substring(5, 7)
      + '/'
      + date.substring(0,4)
  }

  stringToServerDate(date: string): string {
    return ''
      + date.substring(6, 10)
      + '-'
      + date.substring(3, 5)
      + '-'
      + date.substring(0, 2)
      + 'T00:00:00Z'
  }

  toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }))
}
