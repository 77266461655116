import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../_models/user';
import { Constants } from '../../constants';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable()
export class AuthenticationService {
  public user: User;
  public token: string;

  constructor(private http: HttpClient) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
  }

  login(username: string, password: string) {
    const user = { username, password };
    return this.http.post(Constants.API_ENDPOINT + '/operator/login', { username: username, password: password }, {observe: 'response'})
    .pipe(tap((res: any) => {/*{body: {data: User, status: string}, headers: any})*/
      if (res.body.status === '1') {
        this.token = res.headers.get('Authorization');
        console.log(this.token);
        localStorage.setItem('currentUser', JSON.stringify(res.body.data));
        this.user = res.body.data;
      }
    }));
  }

  logout() {
    localStorage.removeItem('currentUser');
  }
}
