import {AlertsService} from './../_services/alerts/alerts.service';
import {ToolsService} from './../tools.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Order} from '../_models/order';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CustomEvent} from '../_models/custom-event';
import {User} from '../_models/user';
import {CountriesService} from '../_services/countries/countries.service';
import {AuthenticationService} from '../_services/authentication/authentication';
import {CommunicationService} from '../_services/communication/communication.service';
import {SoundService} from '../_services/sound/sound.service';
import {OrderService} from '../_services/order/order.service';

const countDownDuration = 60 * 5;

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: 'home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
  countDownConfig: any = {leftTime: countDownDuration, template: '$!m!:$!s!'};
  images: any;
  orderForm: FormGroup;
  pollingOrderDataTimer: any;
  busyTimer: any;
  interval: any;
  agentBusy: Boolean = false;
  user: User;
  order: Order;
  loading: Boolean = false;
  zoomed: String = '';
  maxBirthDate: Date;
  isPrepaid: Boolean;
  countries: any = this.countriesService.countries;
  personalIdTypes: any = this.countriesService.personalIdTypes;


  constructor(public orderService: OrderService,
              private soundService: SoundService,
              private formBuilder: FormBuilder,
              private alertsService: AlertsService,
              public communicationService: CommunicationService,
              public authenticationService: AuthenticationService,
              private router: Router,
              private toolsservice: ToolsService,
              private countriesService: CountriesService) {
    this.pollingOrderDataTimer = setInterval(() => {
      this.tryLoadNextOrder();
    }, 1000);
  }

  getNiceData() {
    const niceFormData = {...this.orderForm.value};
    niceFormData.userDetail.dob = this.toolsservice.stringToServerDate(this.orderForm.value.userDetail.dob);
    niceFormData.personalId.expiry = this.toolsservice.stringToServerDate(this.orderForm.value.personalId.expiry);

    niceFormData.userId = this.order.userId;
    niceFormData.personalId.id = this.order.personalId.id;
    return niceFormData;
  }

  ngOnInit() {
    this.user = this.authenticationService.user;
    this.communicationService.events.subscribe((message: CustomEvent) => {

      if (message.name === 'validateApprove') {
        this.orderForm.value.personalId.status = 'APPROVED';
        return this.orderService.approve(this.getNiceData())
          .subscribe(res => {
            if (this.busyTimer) {
              clearTimeout(this.busyTimer);
            }
            this.orderService.submitting = false;
            this.reset(true);
          });
      }

      if (message.name === 'validateReject') {
        this.orderForm.value.personalId.status = 'REJECTED';
        this.orderForm.value.personalId.rejectionReason = message.data.reason;

        return this.orderService.reject(this.getNiceData())
          .subscribe(res => {
            if (this.busyTimer) {
              clearTimeout(this.busyTimer);
            }
            this.orderService.submitting = false;
            this.reset(true);
          });
      }

      if (message.name === 'agentBack') {
        this.agentBusy = false;
      }
    });
    this.interval = setInterval(this.tryLoadNextOrder.bind(this), 1000);
  }

  reset(navigate?: Boolean) {
    delete this.order; // remove current order
    delete this.orderForm;
    this.images = {};
    if (navigate) {
      this.router.navigate(['', {outlets: {popup: null}}]);
    } // close any popup
  }

  onPickNationality() {
    this.countriesService.getValidIdTypesForNationality(this.order.userDetail.nationality, 'teestthis.order.orderId');
  }

  zoom(name: string | '', event?: any) {
    this.zoomed = (this.zoomed === name ? '' : name);

    if (event) {
      event.stopPropagation();
    }
  }

  // toggleSample() {
  //
  //   if (this.zoomedSample) {
  //     return this.zoomedSample = '';
  //   }
  //
  //   this.toolsservice.toDataURL('assets/images/id-samples/sample1.png')
  //     .then((b64: string) => this.zoomedSample = b64)
  //     .catch(e => {
  //       this.alertsService.addAlert('Could not load the sample image', AlertTypes.danger, null);
  //       console.error(e);
  //     });
  // }

  tryLoadNextOrder() {
    if (!this.order && !this.loading && !this.agentBusy) {
      this.loadNextOrder();
    }
  }

  resetForm() {
    this.generateForm(this.order);
  }

  generateForm(order: Order) {
    this.orderForm = this.formBuilder.group({
      'personalId': this.formBuilder.group({
        'idNumber': [order.personalId.idNumber, Validators.required],
        'idTypeId': [order.personalId.idTypeId, Validators.required],
        'expiry': [this.toolsservice.serverDateToString(order.personalId.expiry), Validators.compose([Validators.required, Validators.pattern('[0-9]{2}/[0-9]{2}/[0-9]{4}')])]
      }),
      'userDetail': this.formBuilder.group({
        'firstName': [order.userDetail.firstName, Validators.required],
        'lastName': [order.userDetail.lastName, Validators.required],
        'dob': [this.toolsservice.serverDateToString(order.userDetail.dob), Validators.compose([Validators.required, Validators.pattern('[0-9]{2}/[0-9]{2}/[0-9]{4}')])],
        'nationality': [order.userDetail.nationality || '', [Validators.required]],
      }),
    });
  }

  loadNextOrder() {
    this.loading = true;
    this.orderService.getPendingId()
      .subscribe((data: any) => {
        const order: Order = data.data;
        if (!order) {
          return;
        }

        this.order = order;
        this.generateForm(order);

        this.images = {};
        this.order.imageDetails.forEach(image => {
          this.images[image.docCategory] = 'data:image/' + image.fileType.toLowerCase() + ';base64,' + image.base64String;
        });

        // delete this.images['PERSONAL_ID_BACK']; // for testing

        if (this.isPrepaid) {
          this.maxBirthDate = new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 10);
        } else {
          this.maxBirthDate = new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 18);
        }

        this.busyTimer = setTimeout(() => {
          this.agentBusy = true;
          this.router.navigate(['/', {outlets: {popup: ['busy']}}]);
          this.reset(false);
        }, 1000 * countDownDuration);

        this.soundService.ding();
      }, () => {
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    clearTimeout(this.pollingOrderDataTimer);
    clearTimeout(this.busyTimer);
  }
}
