import {Routes} from '@angular/router';
import {HomeComponent} from './home2/home.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './_guards/auth-guard.guard';
import {PopupApproveComponent} from './_components/popup-approve/popup-approve.component';
import {PopupRejectComponent} from './_components/popup-reject/popup-reject.component';
import {PopupBusyComponent} from './_components/popup-busy/popup-busy.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'home2', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'approve', outlet: 'popup', component: PopupApproveComponent },
  { path: 'reject', outlet: 'popup', component: PopupRejectComponent },
  { path: 'busy', outlet: 'popup', component: PopupBusyComponent },
  { path: '**', redirectTo: 'home2' }
];
