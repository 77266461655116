import {Component} from '@angular/core';
import {Router} from '@angular/router';
import { OrderService } from '../../_services/order/order.service';
import { CommunicationService } from '../../_services/communication/communication.service';

@Component({
  selector: 'app-popup-approve',
  templateUrl: './popup-approve.component.html'
})
export class PopupApproveComponent {

  constructor(private router: Router, private orderService: OrderService, private commuicationService: CommunicationService) {
  }

  validateApprove() {
    this.commuicationService.emit('validateApprove', {});
    this.router.navigate(['/', {outlets: {popup: null}}]);
  }

  cancelApprove() {
    this.router.navigate(['/', {outlets: {popup: null}}]);
  }
}
