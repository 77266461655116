import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {routes} from './routes';
import {HomeComponent} from './home2/home.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './_guards/auth-guard.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {LoaderComponent} from './_components/loader/loader.component';
import {PopupApproveComponent} from './_components/popup-approve/popup-approve.component';
import {PopupRejectComponent} from './_components/popup-reject/popup-reject.component';
import {CountdownModule} from 'ngx-countdown';
import {DateValueAccessorModule} from 'angular-date-value-accessor';
import {PopupBusyComponent} from './_components/popup-busy/popup-busy.component';
import {NgbModule, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
// import { NgbDateStringParserFormatter } from './home2/date-format';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RfeIdInterceptor } from './_interceptors/RfeId.interceptor';
import { ErrorInterceptor } from './_interceptors/Error.interceptor';
import { AuthenticationService } from './_services/authentication/authentication';
import { OrderService } from './_services/order/order.service';
import { SoundService } from './_services/sound/sound.service';
import { CommunicationService } from './_services/communication/communication.service';
import { AlertsComponent } from './_components/alerts/alerts.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AuthenticationInterceptor } from './_interceptors/Authentication.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LoaderComponent,
    PopupApproveComponent,
    PopupRejectComponent,
    PopupBusyComponent,
    AlertsComponent
  ],
  imports: [
    NgbModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    CountdownModule,
    DateValueAccessorModule,
    FormsModule, ReactiveFormsModule,
    AngularFontAwesomeModule,
    RouterModule.forRoot(routes, {enableTracing: false}) // <-- debugging purposes only
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    OrderService,
    SoundService,
    CommunicationService,
    // {provide: NgbDateParserFormatter, useClass: NgbDateStringParserFormatter},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RfeIdInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    //  (!environment.production ? fakeBackendProvider : null/*undefined*/)
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
