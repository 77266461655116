import {Injectable} from '@angular/core';

export enum AlertTypes {
  light = 'light',
  primary = 'primary',
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  dark = 'dark',
}

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  public alerts: any[] = [];

  constructor() {
  }

  addAlert(text: string, type: AlertTypes, duration?: number) {
    const newAlert = {text, type};
    setTimeout(() => {
      this.removeAlert(newAlert);
    }, duration || 30000);

    this.alerts.push(newAlert);
    if (this.alerts.length > 2) {
      this.alerts.splice(0, 1);
    }
  }

  removeAlert(alert: any) {
    const index = this.alerts.indexOf(alert);
    if (index !== -1) {
      this.alerts.splice(index, 1);
    }
  }
}
