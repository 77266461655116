import { FinalOrder } from './../../_models/order';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Constants } from '../../constants';
import { Order } from '../../_models/order';
import { Observable } from 'rxjs';


@Injectable()
export class OrderService {

  submitting: boolean = false;

  constructor(private http: HttpClient) {
  }

  /**
   * tries to fetch the next order
   * @returns {Observable<User[]>}
   */
  getPendingId() {
    return this.http.post<Order>(Constants.API_ENDPOINT + '/getPendingId', {});
  }

  reject(order: FinalOrder) {
    this.submitting = true;
    return this.http.post(Constants.API_ENDPOINT + '/updatePersonalId', order)
  }
  
  approve(order: FinalOrder) {
    this.submitting = true;
    return this.http.post(Constants.API_ENDPOINT + '/updatePersonalId', order)
  }
}
