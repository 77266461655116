import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication/authentication';
import { noop } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading: Boolean = false;
  submitted: Boolean = false;
  submitting: Boolean = false;
  returnUrl: string;
  userNameKey = 'username';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [ localStorage.getItem(this.userNameKey) || environment.username || '', Validators.required],
      password: [ environment.password || '' , Validators.required]
    });

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.submitting = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .subscribe(
        data => {
          setTimeout(() => {
            this.router.navigate([this.returnUrl]);
            localStorage.setItem(`username`, this.f.username.value);
          }, 1000);
        },
        () => {
          this.loading = false;
          this.submitting = false;
         // this.loginForm.controls.password.reset();
        }, () => {
          this.submitting = false;
          this.loading = false;
        });
  }
}
