import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';



@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  public personalIdTypes: any = [];
  public countries: any = [];

  constructor(private http: HttpClient) {
    this.init();
  }

  init() {
    this.http.post(Constants.API_ENDPOINT + '/bootloader', {})
      .subscribe((res: any) => {
        this.countries.push(...this.objToArray(res.data.countries, true));
        this.countries.sort(this.sortByCountryName)
      });

    this.http.post(Constants.API_ENDPOINT + '/getCheckoutData', {})
      .subscribe((res: any) => {
        const newIdTypes: any = this.objToArray(Object.assign(res.data.postpaidPersonalIdTypes, res.data.prepaidPersonalIdTypes), true);
        this.personalIdTypes.push(...newIdTypes);
      });
  }

  getValidIdTypesForNationality(countryId: string, productId: string) {
    countryId = '200903041337530069';
    productId = '1024';
    this.http.post(Constants.API_ENDPOINT + '/getValidIdTypesForNationality', {
      countryId: countryId,
      productId: productId, // get from Anuj
    });
  }

  private objToArray(obj: Object, addKeyToObjects: Boolean) {
    if (!obj) {
      return [];
    }
    return Object.keys(obj).map(function (key) {
      if (addKeyToObjects) {
        obj[key].objKey = key;
      }

      return obj[key];
    });
  }

  private sortByCountryName(countryA, countryB) {
    if(countryA.en > countryB.en) return 1
    else if(countryA.en == countryB.en) return 0
    else return -1
  }
}
