import { ToolsService } from './../tools.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../_services/authentication/authentication';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  token: string

  constructor(private toolsService: ToolsService, private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Adding the Authentication header that was sent in the login call.
    if (request.method === 'POST' && request.url.indexOf('/login') === -1) {
      if (this.authenticationService.token) {
        request = request.clone({ headers: request.headers.set('Authorization', this.authenticationService.token) });
      }
    }
    
    return next.handle(request)
  }
}

