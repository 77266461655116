import { CustomEvent } from './../../_models/custom-event';
import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class CommunicationService {
  @Output() public events: EventEmitter<CustomEvent> = new EventEmitter();

  constructor() {
  }

  emit(topic: string, data: any) {
    this.events.emit({name : topic, data : data});
  }
}
