import {AlertsService, AlertTypes} from './../_services/alerts/alerts.service';
import {Injectable} from '@angular/core';
import {HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS} from '@angular/common/http';
import {Observable, of, throwError, TimeoutError, noop} from 'rxjs';
import {delay, mergeMap, materialize, dematerialize, timeout} from 'rxjs/operators';
import {Order} from '../_models/order';
import {tap} from 'rxjs/internal/operators/tap';
import {ToolsService} from '../tools.service';
import {map} from 'rxjs/internal/operators/map';
import {catchError} from 'rxjs/internal/operators/catchError';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private toolsService: ToolsService, private alertsService: AlertsService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)

      .pipe(
        timeout(20000),
        map((res: any) => {
          if (res.body && res.body.status == 0) { // soft error
            if (res.body.errorCode === '924') {
            } else if (res.body.errorDescription) {
              this.alertsService.addAlert(`Error : ${res.body.errorDescription} (code : ${res.body.errorCode})`, AlertTypes.danger);
            }
            throw res.body.errorCode; // not use throwError
          }
          return res;
        }, err => { // hard error with wrong status
          this.alertsService.addAlert(`Sorry something went wrong (${err.status})`, AlertTypes.danger);
          console.log(err);
          return err;
        }),
        tap(noop, (e: any) => {
          if (e instanceof TimeoutError) {
            this.alertsService.addAlert(`Sorry request timed out, are you on the right network?`, AlertTypes.danger);
          } else if (e.status === 401) {
            this.router.navigate(['/login']);
            this.alertsService.addAlert('Please login again', AlertTypes.danger);
          } else {
            console.error(e);
          }
        })
      );
  }
}
