import {Component} from '@angular/core';
import {Router} from '@angular/router';
import { OrderService } from '../../_services/order/order.service';
import { CommunicationService } from '../../_services/communication/communication.service';


@Component({
  selector: 'app-popup-busy',
  templateUrl: './popup-busy.component.html'
})
export class PopupBusyComponent {

  constructor(private router: Router, private orderService: OrderService, private commuicationService: CommunicationService) {
  }

  imBack() {
    this.commuicationService.emit('agentBack', {});
    this.router.navigate(['/', {outlets: {popup: null}}]);
  }
}
