import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { CommunicationService } from '../../_services/communication/communication.service';

@Component({
  selector: 'app-popup-reject',
  templateUrl: './popup-reject.component.html'
})
export class PopupRejectComponent implements OnInit {
  rejectionForm: FormGroup;

  constructor(private router: Router, private commuicationService: CommunicationService, private fb: FormBuilder) {
    this.rejectionForm = this.fb.group({
      reason: [false, Validators.required],
    });
  }

  ngOnInit() {
  }

  validateReject() {
    this.commuicationService.emit('validateReject', {reason : this.rejectionForm.value.reason});
    this.router.navigate(['/', {outlets: {popup: null}}]);
  }

  cancelReject() {
    this.router.navigate(['/', {outlets: {popup: null}}]);
  }
}
