import { ToolsService } from './../tools.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { Order } from '../_models/order';
import { environment } from '../../environments/environment';

@Injectable()
export class RfeIdInterceptor implements HttpInterceptor {

  constructor(private toolsService: ToolsService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // array in local storage for registered users
    if (request.method === 'POST') {
      const rfeId: String = this.toolsService.getRandomString(10);
      request = request.clone({ url: request.url + `?rfe_id=${rfeId}`, withCredentials: true });
    }

    return next.handle(request);
  }
}

